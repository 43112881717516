import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import "./assets/style/global.scss";
import ReactGA from "react-ga4";
import App from "components/App/App";

ReactGA.initialize(process.env.GA_TRACKING_ID || "G-S0TN38J00J");
ReactGA.pageview(window.location.pathname + window.location.search);

Sentry.init({
  dsn: "https://a75dbdf711c4424b93bcb970fa16d7c0@o1162451.ingest.sentry.io/6253516",
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root"),
);