import { memo } from 'react';
import { Area, AreaChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import 'components/DefaultAreaChart/DefaultAreaChart.scss';

const DefaultAreaChart = ({
  data,
  dataMap = [{ key: 'y1', name: 'Value', color: '#000000' }],
  height = 260,
  width = '100%',
  aspect,
  margin = {},
  yDomain = ['auto', 'auto'],
  yAllowDecimals = true,
  formatterX,
  formatterY,
}) => {
  if (!data) {
    return <div>Loading ...</div>;
  }
  
  return (
    <ResponsiveContainer className="DefaultAreaChart" width={width} height={height} aspect={aspect}>
      <AreaChart margin={margin} data={data}>
        <defs>
          <linearGradient id="colorUv" x1="0" y1="0" x2="-1" y2="5">
            <stop offset="10%" stopColor="#000700" stopOpacity={0.9}/>
            <stop offset="80%" stopColor="#ffffff" stopOpacity={0}/>
          </linearGradient>
        </defs>
        <XAxis
          domain={['auto', 'auto']}
          type="category"
          dataKey="x"
          tickFormatter={formatterX}
          stroke="#94929e"
          tickMargin={5}
        />
        <YAxis domain={yDomain} stroke="#94929e" tickMargin={5} interval={0} allowDecimals={yAllowDecimals} />
        <CartesianGrid margin={margin} stroke="#A3ACB990" strokeDasharray="4 4" />
        {dataMap.map(({ key, name, color, opacity = 1 }) => {
          return (
            <Area
              key={key}
              name={name}
              type="monotone"
              dataKey={key}
              stroke={color}
              fill="url(#colorUv)"
              fillOpacity={opacity}
            />
          );
        })}
        <Tooltip formatter={formatterY} labelFormatter={formatterX} />
      </AreaChart>
    </ResponsiveContainer>
  );
};

export default memo(DefaultAreaChart);
