// eslint-disable-next-line import/prefer-default-export

export const API_URL = process.env.DATA_URL_PREFIX || "https://aptos-devnet-stats.s3.us-west-2.amazonaws.com/";
export const API_HEADERS = {
  "Content-Type": "application/json",
  Accept: "application/json",
};

export const API_CURRENT_VERSION = "current_version.json";
export const API_NETWORK_STATUS = "current_tps_over_60_seconds.json";
export const API_VERSION_OVERTIME = "version_overtime.json";

export const API_V_UPTIME = "validator_uptime.json";
export const API_FN_UPTIME = "fullnode_uptime.json";
export const API_FN_DOWNSTREAM = "fullnode_downstream.json";
