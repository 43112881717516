import Logo from "components/Logo";
import BackButton from "components/RoundButton/RoundButton";
import "./Navbar.scss";

const Navbar = ({title, buttonText, buttonUrl}) => {
  return (
    <div className="Navbar">
      <div className="left">
        <Logo />
        <div className="title">{title}</div>
      </div>
      <nav className="right">
        {
          buttonText && <BackButton href={buttonUrl} variant="outline">
            {buttonText}
          </BackButton>
        }
      </nav>
    </div>
  );
};

export default Navbar;
