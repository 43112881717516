import GitLogo from "assets/svgs/Git.svg";
import DiscordLogo from "assets/svgs/discord.svg";
import TwitterLogo from "assets/svgs/Twitter.svg";
import MediumLogo from "assets/svgs/medium.svg";
import LinkedInLogo from "assets/svgs/linkedin.svg";

/*
TODO: when we have a better theme/images, re-enable these
import FrontImage from 'assets/images/front_image.jpg';
import BackImage from 'assets/images/back_image.jpg';
 */

// Hero (left copy)
const NavbarData = {
  // TODO: CHANGE THIS TO THE SPECIFIC NETWORK
  title: "Devnet Status Dashboard",
  buttonUrl: "https://www.aptoslabs.com/",
  buttonText: "Back to Aptos Labs",
};

// Hero (left copy)
const HeroData = {
  title: "Welcome to the Aptos Network Dashboard!",
  subtitle: "Track the status of the Aptos Network and its nodes",
};

const CurrentNumbersData = {
  value1Name: "Current Version",
  value2Name: "Current TPS",
};

// Network Status
const NetworkStatusData = {
  title: "Transactions per second (TPS)",
  subtitle: "Track the transactions committed per second (TPS) including block metadata transactions.",
  chart: {name: "TPS", color: "#000000"},
};

// Versions over time
const VotStatusData = {
  title: "Total committed transactions (version)",
  subtitle: "Track the total number of committed transactions or Blockchain version.",
  chart: {name: "Version", color: "#000000"},
};

// Versions over time
const NodeStatusData = {
  title: "Node Status",
  subtitle: (
    <>
      Check Validator and FullNode status, their uptime, the validator vote distribution in the last hour, and the
      number of downstream clients.
      <ul>
        <li><b>Uptime</b> represents the Validator or FullNodes time since last reboot.</li>
        <li><b>V Contribution</b> represents the validators committed votes in recent consensus rounds.</li>
        <li><b>FN Downstream</b> represents the total number of incoming connections on this FullNode.</li>
      </ul>
    </>
  ),
  // Make sure to include 5 table headers!
  tableHeaders: ["Company", "V Uptime", "V Contribution", "Fn Uptime", "FN Downstream"],
};

// CTA
const MainnetCtaData = {
  title: "How to get connected to the devnet",
  subtitle: (
    <>
      <ul>
        <li>Connect to Devnet&#39;s RESTful Endpoint: <a
          href="https://dev.fullnode.aptoslabs.com">https://dev.fullnode.aptoslabs.com/</a>.
        </li>
        <li><a href="https://aptos.dev/main/docs/configure-run-public-fullnode">Start and connect a FullNode</a>.
        </li>
      </ul>
    </>
  ),
};

// PreFooter (with CTA)
const PreFooterData = {
  title: "Visit the Aptos Developer Site",
  subtitle: "Find more information about the network, validators and fullNodes.",
  /*
  TODO: when we have a better theme/images, re-enable these
  frontImage: FrontImage,
  backImage: BackImage,
   */
  ctaUrl: "https://aptos.dev/",
  ctaText: "Learn More",
};

// Footer
const FooterData = {
  supportText: "If you have any questions, please contact us at ",
  socialLinks: [
    {title: "Git", url: "https://github.com/aptoslabs", icon: GitLogo},
    {title: "Discord", url: "https://discord.gg/zTDYBEud7U", icon: DiscordLogo},
    {title: "Twitter", url: "https://twitter.com/aptoslabs/", icon: TwitterLogo},
    {title: "Medium", url: "https://aptoslabs.medium.com/", icon: MediumLogo},
    {title: "LinkedIn", url: "https://www.linkedin.com/company/aptoslabs/", icon: LinkedInLogo},
  ],
  legalLinks: null, /* [
    TODO: ENABLE THESE ONCE LIVE
    {title: "PRIVACY POLICY", url: "https://www.aptoslabs.com/en-us/privacy/#privacy_policy"},
    {title: "COOKIES", url: "https://www.aptoslabs.com/en-us/privacy/#cookies_policy"},
    {title: "TERMS OF USE", url: "https://www.aptoslabs.com/en-us/privacy/#terms_of_use"},
  ], */
};

// eslint-disable-next-line import/prefer-default-export
export {
  NavbarData,
  HeroData,
  CurrentNumbersData,
  NetworkStatusData,
  VotStatusData,
  NodeStatusData,
  MainnetCtaData,
  PreFooterData,
  FooterData,
};
