import Numbers from 'components/Numbers/Numbers';
import Widget from 'components/Widget/Widget';
import classNames from 'classnames';
import { API_CURRENT_VERSION, API_HEADERS, API_URL } from 'config';
import useAxios from 'axios-hooks';
import VersionNumIcon from 'assets/svgs/query_the_blockchain.svg';
import CurrentTpsIcon from 'assets/svgs/wallet_integration_guide.svg';
import { useMemo } from 'react';
import { CurrentNumbersData } from 'body-copy';

const CurrentNumbers = ({ className, currentTps }) => {
  // eslint-disable-next-line no-unused-vars
  const [{ data, loading, error }, refetch] = useAxios({
    url: `${API_URL}${API_CURRENT_VERSION}`,
    headers: API_HEADERS,
  });

  const formattedData = useMemo(() => {
    return [
      { value: data?.[0]?.value?.[1], icon: VersionNumIcon, name: CurrentNumbersData.value1Name },
      { value: currentTps, icon: CurrentTpsIcon, name: CurrentNumbersData.value2Name },
    ];
  }, [data, currentTps]);

  return (
    <Widget className={classNames('numbers', className)} padding={false} loading={loading} error={error}>
      <Numbers data={formattedData} />
    </Widget>
  );
};

export default CurrentNumbers;
