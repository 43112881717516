import './Legend.scss';

const Legend = ({ name, color }) => {
  return (
    <span className="Legend">
      <span className="square" style={{ backgroundColor: color }} />
      {name}
    </span>
  );
};

export default Legend;
