import classNames from 'classnames';
import 'components/RoundButton/RoundButton.scss';

const RoundButton = ({ href, variant = 'filled', classMame, children }) => {
  return (
    <a className={classNames('RoundButton', variant, classMame)} href={href} rel="noopener">
      <span>{children}</span>
    </a>
  );
};

export default RoundButton;
