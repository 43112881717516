import "./PreFooter.scss";

import ArrowIcon from "assets/svgs/arrow-right.svg";

const PreFooter = ({frontImage, backImage, title, subtitle, ctaText, ctaUrl}) => {

  if (frontImage && backImage) {
    return (
      <section className="PreFooter container">
        <div className="grid u-max-content">
          <div className="left">
            <img src={backImage} className="back-image" alt="" />
            <img src={frontImage} className="front-image" alt="" />
          </div>
          <div className="right">
            <h2 className="title">{title}</h2>
            <p>{subtitle}</p>
            <a className="link" href={ctaUrl}>
              {ctaText}
              <span className="round-icon">
              <img src={ArrowIcon} alt="" />
            </span>
            </a>
          </div>
        </div>
      </section>
    );
  }

  return (
    <section className="PreFooter container">
      <div className="grid u-max-content">
        <div className="left">
          <h2 className="title">{title}</h2>
          <p>{subtitle}</p>
          <a className="link" href={ctaUrl}>
            {ctaText}
            <span className="round-icon">
              <img src={ArrowIcon} alt="" />
            </span>
          </a>
        </div>
      </div>
    </section>
  );
};

export default PreFooter;
