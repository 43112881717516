import "./App.scss";
import { useState } from "react";
import Hero from "components/Hero/Hero";
import Footer from "components/Footer/Footer";
import Navbar from "components/Navbar/Navbar";
import PreFooter from "components/PreFooter/PreFooter";
import NetworkStatus from "widgets/NetworkStatus";
import VersionOverTime from "widgets/VersionOverTime";
import { NavbarData, HeroData, PreFooterData, FooterData } from "body-copy";
// TODO: Bring this back later when we need it
// import MainnetCta from "widgets/MainnetCta";
// import NodeStatus from 'widgets/NodeStatus';

const App = () => {
  const [currentTps, setCurrentTps] = useState(null);

  const footerProps = {
    /*
    TODO: when we have a better theme/images, re-enable these
    frontImage: PreFooterData.frontImage,
    backImage: PreFooterData.backImage,
     */

  };
  // eslint-disable-next-line no-throw-literal
  return (
    <div className="App">
      <header>
        <Navbar title={NavbarData.title} buttonText={NavbarData.buttonText} buttonUrl={NavbarData.buttonUrl} />
        <Hero title={HeroData.title} subtitle={HeroData.subtitle} currentTps={currentTps} />
      </header>
      <main className="main">
        <div className="container widgets grid u-max-content ">
          <NetworkStatus className="col-12 lg:col-6" setCurrentTps={setCurrentTps} />
          <VersionOverTime className="col-12 lg:col-6" />
          {/*
          <NodeStatus className="col-12" />
          <MainnetCta className="mt-30 col-12" />
           */}
        </div>
        <PreFooter
          title={PreFooterData.title}
          subtitle={PreFooterData.subtitle}
          ctaText={PreFooterData.ctaText}
          ctaUrl={PreFooterData.ctaUrl}
          {...footerProps}
        />
      </main>
      <Footer
        supportText={FooterData.supportText}
        socialLinks={FooterData.socialLinks}
        legalLinks={FooterData.legalLinks}
      />
    </div>
  );
};

export default App;
