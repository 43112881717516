import './Hero.scss';
import { memo } from 'react';
import CurrentNumbers from 'widgets/CurrentNumbers';

const Hero = ({ title, subtitle, currentTps }) => {
  return (
    <div className="Hero">
      <div className="container wrapper grid">
        <div className="col-12 xl:col-6 left">
          <h1 className="title">{title}</h1>
          <p>{subtitle}</p>
        </div>
        <CurrentNumbers className="col-12 xl:col-6" currentTps={currentTps} />
      </div>
    </div>
  );
};

export default memo(Hero);
