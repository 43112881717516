import DefaultAreaChart from 'components/DefaultAreaChart/DefaultAreaChart';
import { formatTimestamp, formatData } from 'utils';
import Widget from 'components/Widget/Widget';
import useAxios from 'axios-hooks';
import { API_HEADERS, API_URL, API_VERSION_OVERTIME } from 'config';
import classNames from 'classnames';
import { useMemo } from 'react';
import { VotStatusData } from 'body-copy';

const VersionOverTime = ({ className }) => {
  // eslint-disable-next-line no-unused-vars
  const [{ data, loading, error }, refetch] = useAxios({
    url: `${API_URL}${API_VERSION_OVERTIME}`,
    headers: API_HEADERS,
  });

  const formattedData = useMemo(() => {
    return formatData(data);
  }, [data]);

  return (
    <Widget
      className={classNames(className)}
      loading={loading}
      error={error}
      title={VotStatusData.title}
      subtitle={VotStatusData.subtitle}
      legendData={[{ name: VotStatusData.chart.name, color: VotStatusData.chart.color }]}
    >
      <DefaultAreaChart
        data={formattedData}
        dataMap={[{ key: 'y1', name: VotStatusData.chart.name, color: VotStatusData.chart.color }]}
        formatterX={formatTimestamp}
      />
    </Widget>
  );
};

export default VersionOverTime;
