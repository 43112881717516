import "./Footer.scss";
import Logo from "../Logo";

const Footer = ({socialLinks, legalLinks, supportText}) => {
  return (<footer className="Footer">
    <div className="top grid">
      <div className="col-12 md:col-6 lg:col-4">
        <Logo />
      </div>
      <p className="col-12 md:col-6 lg:col-4 text">
        {supportText}
        <br />
        <a href="mailto:info@aptoslabs.com">info@aptoslabs.com</a> or <a
        href="mailto:press@aptoslabs.com">press@aptoslabs.com</a>

      </p>
      <nav className="col-12 md:col-6 lg:col-4 social-links">
        {socialLinks.map((link) => (<a
          key={link.title}
          className="social-link"
          href={link.url}
          target="_blank"
          rel="noopener noreferrer"
          title={link.title}
        >
          <img className="icon" src={link.icon} alt={`${link.title} Icon`} />
        </a>))}
      </nav>
    </div>
    {
      legalLinks &&
      <nav className="container bottom">
        {legalLinks.map((link) => (<a key={link.title} className="link" href={link.url} rel="noopener">
          {link.title}
        </a>))}
      </nav>
    }
  </footer>);
};

export default Footer;
