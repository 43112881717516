import Widget from 'components/Widget/Widget';
import DefaultAreaChart from 'components/DefaultAreaChart/DefaultAreaChart';
import { formatData, formatTimestamp } from 'utils';
import classNames from 'classnames';
import useAxios from 'axios-hooks';
import { API_HEADERS, API_NETWORK_STATUS, API_URL } from 'config';
import { useEffect, useMemo } from 'react';
import { NetworkStatusData } from 'body-copy';

const NetworkStatus = ({ className, setCurrentTps }) => {
  // eslint-disable-next-line no-unused-vars
  const [{ data, loading, error }, refetch] = useAxios({
    url: `${API_URL}${API_NETWORK_STATUS}`,
    headers: API_HEADERS,
  });

  const formattedData = useMemo(() => {
    return formatData(data);
  }, [data]);

  useEffect(() => {
    if (formattedData?.length) {
      setCurrentTps(parseFloat(formattedData[formattedData.length - 1].y1).toFixed(2));
    }
  }, [formattedData]);

  return (
    <Widget
      loading={loading}
      error={error}
      title={NetworkStatusData.title}
      legendData={[{ name: NetworkStatusData.chart.name, color: NetworkStatusData.chart.color }]}
      subtitle={NetworkStatusData.subtitle}
      className={classNames(className)}
    >
      <DefaultAreaChart
        data={formattedData}
        dataMap={[{ key: 'y1', name: NetworkStatusData.chart.name, color: NetworkStatusData.chart.color }]}
        yDomain={[0, 'auto']}
        margin={{ left: -30 }}
        yAllowDecimals={false}
        formatterX={formatTimestamp}
        formatterY={(value) => parseFloat(value).toFixed(2)}
      />
    </Widget>
  );
};

export default NetworkStatus;
