import { memo } from 'react';
import './Numbers.scss';

const Numbers = ({ data = [] }) => {
  return (
    <div className="Numbers">
      {data.map(({ value, name, icon }) => (
        <div key={name} className="cell">
          <img className="icon" src={icon} alt="" />
          <div className="number">{value}</div>
          <div className="text">{name}</div>
        </div>
      ))}
    </div>
  );
};

export default memo(Numbers);
