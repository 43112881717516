import './Widget.scss';
import classNames from 'classnames';
import ErrorMessage from 'components/ErrorMessage/ErrorMessage';
import Loading from 'components/Loading/Loading';
import Legend from '../Legend/Legend';

const Widget = ({ className, title, legendData = [], subtitle, padding = true, loading, error, children }) => {
  const showLegend = !!legendData?.length;

  let content;

  if (error) {
    content = <ErrorMessage />;
  } else if (loading) {
    content = <Loading />;
  } else {
    content = children;
  }

  return (
    <div className={classNames('Widget', className, { padding })}>
      {(title || subtitle || showLegend) && (
        <div className="header">
          {(title || showLegend) && (
            <div className="header-top">
              {title && <h2 className="title">{title}</h2>}
              {showLegend && (
                <div className="legend">
                  {legendData.map((legend) => (
                    <Legend key={legend.name} {...legend} />
                  ))}
                </div>
              )}
            </div>
          )}
          {subtitle && <p className="subtitle">{subtitle}</p>}
        </div>
      )}
      {content}
    </div>
  );
};
export default Widget;
